<template>
	<div>
		<DateRangeV2
            :start.sync="start_date"
            :end.sync="end_date"
            @submit="loadTransactions"
            :ShowTodayButton="true"
            :dateSelect="true"
            :periodToShow="['personalise','jour','mois','mois_dernier']"
        />

        <CustomTable
            id_table="bridge_transactions"
            ref="bridge_transactions"
            :items="bridge_transactions"
            :busy.sync="table_busy"
            primaryKey="bridgetransaction_id"
            :hide_if_empty="true"
            :externSlotColumns="extern_slot_columns"
        >
			<template v-slot:[`custom-slot-cell(account_name)`]="{ data }">
				{{ data.bridge_account.bridge_bank.bridgebank_name }} - {{ data.bridge_account.bridgeaccount_name }}<br>
				{{ data.bridge_account.bridgeaccount_iban }}
			</template>
			<template v-slot:[`custom-slot-cell(invoices)`]="{ data }">
				<div v-for="(invoice_num, key) in data.invoices" :key="key">
					{{ invoice_num }}
				</div>
			</template>
    	</CustomTable>
	</div>
</template>

<script type="text/javascript">
    import Accounting from "@/mixins/Accounting.js"
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"

	export default {
		name: 'BankFlowList',
		mixins: [Accounting, Navigation, Shutter, ShutterInvoice],
		data () {
			return {
				extern_slot_columns: ['account_name', 'invoices'],
                start_date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                end_date: new Date(),
				bridge_transactions: [],
				table_busy: false,
				events_tab: {
                    'TableAction::goToAssignTransaction': this.setupAssignationFlowTransaction,
                },
			}
		},

		methods: {
			async loadTransactions() {
				this.table_busy = true
				this.bridge_transactions = await this.getBridgeTransaction(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
				this.table_busy = false
			},
			reload() {
				this.$refs.bridge_transactions.unselectAll()
				this.loadTransactions()
			}
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2')
		}
	}

</script>